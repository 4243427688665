.userprofile{
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.myButtonsContainer{
    height: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px
}