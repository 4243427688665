.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);

    padding: 5px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.MyModalButton{
    margin: 10px
}