
.radioLabelGoodAnswer{
    color: green !important;
    font-weight: bold;
}

.radioLabelBadAnswer{
    color: silver !important;
}

.radioLabelBadSelectedAnswer{
    color: lightcoral !important;
}

.radioControlGoodAnswer{
    color: green !important;
}

.radioControlBadAnswer{
    color: silver !important;
}

.radioControlBadSelectedAnswer{
    color: lightcoral !important;
}

.lastRadio{
    padding-bottom: 5px;
}

:root {
    --color: blue;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.radio {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 1.05rem;
    color: var(--color);

    &:focus-within {
        .radio__label {
            transform: scale(1.05);
            opacity: 1;
        }
    }
}

.radio__label {
    line-height: 1;
    transition: 180ms all ease-in-out;
    opacity: 0.8;
    padding-top: 3px;
    text-align: left;
    padding-left: 10px;
    color: black;
}

.radio__input {
    display: flex;
    padding-top: 5px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:focus + .radio__control {
            box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
        }
    }
}

.radio-gradient input:checked + .radio__control {
    background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
}

.radio-before {
    .radio__control {
        display: grid;
        place-items: center;
    }

    input + .radio__control::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        box-shadow: inset 0.5em 0.5em currentColor;
        border-radius: 50%;
        transition: 180ms transform ease-in-out;
        transform: scale(0);
    }

    input:checked + .radio__control::before {
        transform: scale(1);
    }
}

.radio__control {
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
    transform: translateY(-0.05em);
}