.myFlopDisplayerFieldset{
    border: 1px solid black !important;
    border-radius: 1.5rem;

    padding: 15px
}

.myFlopDisplayerLegend{
    float: none;
    margin-bottom: 0;

    padding-left: 5px;
    padding-right: 5px;
}