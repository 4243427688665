.suggestions{
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.suggestionsExplanation{
    font-size: x-large;
    color: tomato;
}

.emailSmall{
    font-size: medium;
}

.myBlocks{
    border: 1px solid grey;
    border-radius: 1.5rem;
    padding: 30px;
}

