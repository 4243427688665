.questionDescription{
    display: flex;
    flex-direction: row;
    margin: 10px 5px 5px 5px;

    border-radius: 1.5rem;

    border-left: 0 solid #00ff99;
    transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;

    padding: 2px;
    margin: 60px 5px 0 5px;
    border-color: black;
    border: 1px solid;

    text-align: left;
    font-size:1em;
}

.questionTextDescription{
    display: flex;
    flex-direction: column;
    padding: 10px;
    white-space: pre-line;
}

.descriptionHeader{
    font-size: large;
    color: tomato;
    border: 1px solid tomato;
    border-radius: 1.5rem;
    padding: 5px 20px 5px 20px
}

.MyQuestionSection{
    color: black;
    border: 1px solid grey;
    border-radius: 1.5rem;
    margin-top: 15px;
    padding: 5px 20px 5px 20px
}

.descriptionText{
    margin-top: 10px
}

.questionText{
    margin-top: 10px
}