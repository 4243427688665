.static-square-pocket-unselected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    background-image: linear-gradient(#ceedfc, #b3d7f4);
    color: black;
    text-align: center;
    padding: 0px;
}

.static-square-pocket-selected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    font-weight: bold;
    background-image: linear-gradient(#43beff, #296cac);
    color: white;
    text-align: center;
    padding: 0px;
}

.static-square-suit-unselected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    background-image: linear-gradient(#fff8d4, #ffe78b);
    color: black;
    text-align: center;
    padding: 0px;
}

.static-square-suit-selected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    font-weight: bold;
    background-image: linear-gradient(#fee78a, #e38800);
    color: darkslategrey;
    text-align: center;
    padding: 0px;
}

.static-square-offsuit-unselected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    background-image: linear-gradient(#fed7cf, #f7c5c1);
    color: black;
    text-align: center;
    padding: 0px;
}

.static-square-offsuit-selected {
    width: 28px;
    height: 28px;
    border: thin solid;
    border-color: lightgrey;
    border-radius: 3px;
    font-size: x-small;
    font-weight: bold;
    background-image: linear-gradient(#ff7d60, #f22e1f);
    color: darkslategrey;
    text-align: center;
    padding: 0px;
}