.myQuestionAnswerFieldset{
    border: 1px solid black !important;
    border-radius: 1.5rem;
    padding-left: 10px;
    margin-top: 15px
}

.myQuestionAnswerLegend{
    float: none;
    padding: inherit;
    margin-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.myCaptureAnswerDisplayer{
    padding: 15px
}

.answerButton {
    margin: 15px 5px 0 5px;
}