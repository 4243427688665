.home{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome{
    font-size: x-large;
    color: orangered;
}

.menuWithImageDescription{
    display: flex;
}

.menuDescription{
    margin: 15px;
    border: solid 1px grey;
    padding: 30px;
    border-radius: 1.5rem;
}

.mainPresentationDescription{
    display: flex;
    margin: 15px;
    border: solid 1px grey;
    padding: 20px 20px 0 20px;
    border-radius: 1.5rem;
}

.topRightPresentationDescription{
    font-size: larger;
}

.rightPresentationDescription{
    font-size: larger;
    padding-left: 15px;
}

.enjoy{
    font-size: larger;
    color: tomato;
    display: flex;
    justify-content: center;
}

.myButtonAreaContainer{
    height: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px
}


