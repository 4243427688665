.middlePart{
    width: 250px;
    text-align: center;
    vertical-align: middle;
}

.questionDisplayer {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.playerBlockArea{
    margin-left: 10px;
    padding: 15px 5px 0 5px;
}