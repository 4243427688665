.myScoreFieldset {
    border: 1px solid tomato !important;
    border-radius: 1.5rem;
    margin-top: 20px;
}

.myScoreLegend{
    float: none;
    padding: inherit;
    margin-bottom: 0;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.myScoreText{
    text-align: center;
    padding-bottom: 15px
}