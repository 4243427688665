.buttonStartQuizz{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.myQuizzSelectorFieldset {
    border: 1px solid grey !important;
    border-radius: 1.5rem;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px
}

.myLegendQuizzSelector{
    font-size: large;
    float: none;
    padding: inherit;
    margin-bottom: 0
}

.questionSection{
    display: flex;
    flex-direction: column;
    text-align: center;
    color: tomato;
}

.questionTypeUnselected {
    font-size: small;
    background-color: white;

    border: 1px solid;
    border-radius: 1.5rem;
    border-color: lightgrey !important;

    margin: 5px 5px 0 0;
    padding: 5px 10px 5px 10px;
}

.questionTypeSelected {
    font-size: small;
    background-color: white;

    border: 1px solid;
    border-radius: 1.5rem;
    border-color: tomato !important;

    margin: 5px 5px 0 0;
    padding: 5px 10px 5px 10px;
}

.lastQuestion{
    margin-bottom: 10px
}