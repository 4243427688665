.answerDisplayer{
    border-radius: 1.5rem;

    border-left: 0 solid #00ff99;
    transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;

    margin: 10px 5px 0 5px;
    padding: 10px;
    border-color: black;
    border: 1px solid
}