.buttonStartRanges{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.myRangesSelectorFieldset {
    border: 1px solid grey !important;
    border-radius: 1.5rem;
    padding-left: 10px;
    padding-right: 5px
}

.myRangesLegendSelector{
    font-size: large;
    float: none;
    padding: inherit;
    margin-bottom: 0
}

.MyQuestionSelectorSection{
    display: flex;
    flex-direction: column;
    text-align: center;
    color: tomato;
    margin-top: 15px
}

.rangesSection{
    display: flex;
    flex-direction: row;
    text-align: center;
    color: tomato;
}

.myRangeDisplayButton{
    border-radius: 1.5rem;
    margin: 10px 5px 5px 5px;
    padding-left: 5px;
}

.MyPositionFilters{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
}